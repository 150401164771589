@import "../../../base.scss";

.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .input-header {
    display: flex;
    width: 100%;
    font-size: 14px;
    margin-bottom: 8px;
    text-transform: capitalize;
  }
  .prefix-input-container {
    display: flex;
    align-items: center;
    height: 40px;
    background-color: white;
    border: 0.3px solid #005ea3;
    border-radius: 6px;
    width: 100%;
    &.failed {
      border: 1px solid red;
    }
    &.disabled {
      background-color: #e6edff;
    }
    .inner-input {
      border: none;
      outline: none;
      padding: 0px 8px;
      height: 90%;
      width: 100%;
      border-radius: 7px;
      // font-family: $fonts-regular;
      font-size: 15px;
      line-height: 16px;
    }
  }
}
