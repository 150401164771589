@import "base";

.screen-dashboard-container {
    height: calc(100% - 3rem);
    overflow-x: auto;
    padding: 0.5vw 0 0.5vw 1vw;
    > .col {
        height: 100%;
        min-width: 40vw;
        margin-right: 1vw;
        > div {
            flex: 1;
            overflow: hidden;
            &:not(:first-child) {
                margin-top: 1vw;
            }
        }
    }
}
