@import "base";

.screen-notifications-send-container {
    height: calc(100% - 3rem);
    padding: 0.5vw 1vw;
    .screen-notifications-send-inner-container {
        height: 100%;
        .screen-notifications-send-header {
            @include card($border-radius: 6px);
            padding: 0.5vw 1vw;
            .icon.icon-close {
                height: 1.3rem;
                width: 1.3rem;
                margin-right: 0.5vw;
            }
            h2 {
                text-transform: uppercase;
                font-size: 1.125rem;
                font-weight: 700;
                margin: 0.5rem 0;
            }
            .buttons-raised-container {
                margin-left: auto;
            }
        }
        .screen-notifications-send-content{
            @include card($border-radius: 6px);
            padding: 0.5vw 1vw;
            margin-top: 0.5vw;
            flex: 1;
            overflow: auto;
        }
    }
}
