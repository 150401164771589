@import '../../base.scss';
.server-auto-control-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include shadow-large;
  padding: 0.2vw;
  border-radius: 8px;
  border: 1px solid #45667a;
  background-color: white;
  &.off {
    border: 1px solid #e5214e;
    background-color: #ffeff2;
  }
  &.on {
    border: 1px solid #2ad399;
    background-color: #e5fff6;
  }
  p {
    font-weight: bold;
    margin: 0px;
    margin-left: 12px;
  }
}
.server-setting-text {
  font-size: 14px;
  text-transform: capitalize;
}
