@import "../../../base.scss";
.button-container {
  padding: 10px 20px;
  background-color: white;
  border-radius: 7px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 13px;
  @include shadow-small;
  &.disabled {
    opacity: 0.3;
    cursor: default;
  }
  &.selected {
    background-color: #25e083;
    color: white;
    box-shadow: none;
  }
  &.dashed {
    border: 1px dashed #25e083;
    color: #25e083;
    box-shadow: none;
  }
  &.cancel {
    background-color: rgb(245, 68, 68);
    color: white;
    box-shadow: none;
  }
  &.delete {
    border: 1px solid #fd415e;
  }
  &.transparent {
    background-color: transparent;
    color: black;
    box-shadow: none;
    border: 1px solid red;
  }
  &.white-transparent {
    background-color: transparent;
    color: white;
    box-shadow: none;
    border: 1px solid white;
  }
  &.white{
    background-color: white;
    color: #24a1d3;
    box-shadow: none;
    font-weight: bolder;
  }
  &.mobile-button {
    background-color: #e6eef4;
    color: black;
    box-shadow: none;
    font-weight: bolder;
  }
}

@media screen and (max-width: 1400px) {
  .button-container {
    font-size: 12px;
  }
}
