@import "base";

.stats-downloads-container {
    width: 100%;
    height: 100%;
    @include card($border-radius: 6px);
    padding: 0.75rem;
    .stats-downloads-navigation .button-tab-container {
        margin: 0.75rem 0;
    }
    .stats-downloads-content {
        flex: 1;
        overflow: auto;
    }
}
