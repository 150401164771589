@import '../../base.scss';

.main-container {
  .cards-container {
    height: 25%;
    width: 100%;
    padding: 10px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 30px;
    .card-grid {
      height: 100%;
      min-width: 40%;
      border-radius: 7px;
      @include shadow-small;
      .card-header {
        width: 100%;
        padding: 5px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        @include shadow-small;
        font-size: 15px;
        cursor: pointer;
        justify-content: space-between;
        .card-header-text {
          text-transform: uppercase;
          margin: 0px 0px 0px 5px;
          &.selected {
            color: white;
            font-weight: bold;
          }
        }
        .row {
          gap: 10px;
          span {
            font-size: 11px;
            &.selected {
              color: white;
            }
          }
          .icon-settings {
            width: 18px;
            height: 18px;
          }
        }
        &.selected {
          background-color: #002c78;
          color: white;
        }
      }
      .card-body {
        padding: 1vw;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        .card-row {
          display: flex;
          width: 49.1%;
          height: 45px;
          border: 1px solid #d2e0fa;
          border-radius: 7px;
          padding: 5px;
          align-items: center;
          &.smaller {
            width: 31.5%;
          }
          &.darker {
            background-color: #eaeff1;
          }
          .row-data {
            font-size: 16px;
            font-weight: 400;
            width: 99%;
            display: flex;
            gap: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1490px) {
  .main-container {
    .cards-container {
      .card-grid {
        .card-body {
          .card-row {
            width: 45%;
            .row-data {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
