$color-white: #ffffff;
$color-whiteish: #f2f6fa;

$color-text: #050508;
$color-border: #2f4858;
$colors-shadow: #00347026;

$color-charts-2: #b5bbc1;
$color-charts-3: #344f77;
$color-charts-4: #5b4f8e;
$color-charts-5: #924490;
$color-charts-6: #c42d79;
$color-red: #e5214e;
$color-charts-8: #505860;
$color-charts-9: #13121c;
$color-green: #2ad399;
$color-charts-11: #1f1d2b;
$color-charts-12: #595667;

@mixin shadow-small {
    box-shadow: 0px 1px 4px 0px $colors-shadow;
}
@mixin shadow-large {
    box-shadow: 0px 5px 15px 0px $colors-shadow;
}

%flex {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
}

@mixin card($border-radius: 0 0 6px 6px, $background: $color-white) {
    @include shadow-large;
    background: $background;
    border-radius: $border-radius;
}

@mixin remove-scrollbars {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

@font-face {
    font-family: "Montserrat";
    src: url("assets/fonts/Montserrat-Thin.ttf") format("truetype");
    font-style: normal;
    font-weight: 100;
}
@font-face {
    font-family: "Montserrat";
    src: url("assets/fonts/Montserrat-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: "Montserrat";
    src: url("assets/fonts/Montserrat-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: "Montserrat";
    src: url("assets/fonts/Montserrat-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: "Montserrat";
    src: url("assets/fonts/Montserrat-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
}
