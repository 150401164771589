@import 'base';

.screen-messages-send-container {
  height: calc(100% - 3rem);
  padding: 0.5vw 1vw;
  .screen-messages-send-inner-container {
    height: 100%;
    .screen-messages-send-header {
      @include card($border-radius: 6px);
      padding: 0.5vw 1vw;
      .icon.icon-close {
        height: 1.3rem;
        width: 1.3rem;
        margin-right: 0.5vw;
      }
      h2 {
        text-transform: uppercase;
        font-size: 1.125rem;
        font-weight: 700;
        margin: 0.5rem 0;
      }
      .buttons-raised-container {
        margin-left: auto;
      }
    }
    .screen-messages-send-content {
      @include card($border-radius: 6px);
      padding: 0.5vw 1vw;
      padding-top: 20px;
      margin-top: 0.5vw;
      flex: 1;
      overflow: auto;
      .message-content-container {
        height: 80px;
      }
      > .row {
        > .row.image {
          margin-left: 20px;
          .icon.icon-close {
            width: 20px;
            height: 20px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
