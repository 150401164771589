@import 'base';

.stats-top-container {
  width: 100%;
  height: 100%;
  @include card($border-radius: 6px);
  padding: 0.75rem;
  .stats-top-header {
    justify-content: space-between;
    h2 {
      text-transform: uppercase;
      font-size: 1.125rem;
      font-weight: 700;
      margin: 0.5rem 0;
    }
    > .row {
      align-self: flex-start;
      span {
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 300;
      }
      .icon-settings {
        height: 1.2rem;
        width: 1.2rem;
        min-width: 1.2rem;
        margin-left: 0.5rem;
      }
      .icon-search {
        height: 1.2rem;
        width: 1.2rem;
        min-width: 1.2rem;
        margin-left: 0.5rem;
      }
    }
  }
  .stats-top-navigation .button-tab-container {
    margin: 0.75rem 0;
  }
  .stats-top-content {
    flex: 1;
    overflow: auto;
    .stats-top-content-item-container {
      border: 1px solid $color-border;
      border-radius: 6px;
      justify-content: space-between;
      padding: 0.5rem;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
      .col:nth-child(1) {
        h3 {
          text-transform: uppercase;
          font-weight: 700;
          margin: 0 0 0.25rem;
          font-size: 1.125rem;
        }
        p {
          font-size: 1rem;
          text-transform: uppercase;
          margin: 0;
        }
      }
      .row {
        .col:nth-child(1) {
          text-align: center;
          p {
            font-size: 1.25rem;
            font-weight: 700;
            margin: 0;
          }
          span {
            font-weight: 700;
            text-transform: uppercase;
            margin: 0;
          }
        }
        .col:nth-child(2) {
          display: flex;
          align-items: flex-start;
          height: 100%;
          .icon-info {
            height: 1.2rem;
            width: 1.2rem;
            min-width: 1.2rem;
          }
        }
      }
    }
  }
}

.user-activity-status {
  width: 100%;
  border: 1px solid $color-border;
  border-radius: 7px;
  padding: 1vw;
  > .row {
    .user-avatar {
      background-size: cover;
      background-position: center;
      width: 4rem;
      height: 4rem;
      border-radius: 100%;
    }
    .user-name {
      font-size: 19px;
      font-weight: bold;
      text-transform: uppercase;
      margin-top: 0px;
      margin-bottom: 10px;
    }
    .user-role {
      font-size: 14px;
      text-transform: uppercase;
      margin: 0px !important;
    }
    .user-info {
      font-size: 16px;
    }
  }
}
