@import "base";

.stats-reports-container {
    width: 100%;
    height: 100%;
    @include card($border-radius: 6px);
    padding: 0.75rem;
    .stats-reports-header {
        justify-content: space-between;
        h2 {
            text-transform: uppercase;
            font-size: 1.125rem;
            font-weight: 700;
            margin: 0.5rem 0;
        }
        > .row {
            align-self: flex-start;
            span {
                text-transform: uppercase;
                font-size: 0.875rem;
                font-weight: 300;
            }
            .icon-settings {
                height: 1.2rem;
                width: 1.2rem;
                min-width: 1.2rem;
                margin-left: 0.5rem;
            }
        }
    }
    .stats-reports-content {
        margin-top: 0.75rem;
        flex: 1;
        overflow: auto;
        .stats-reports-item-container {
            border: 1px solid $color-border;
            border-radius: 6px;
            justify-content: space-between;
            padding: 0.5rem;
            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
            > *:not(:last-child) {
                margin-bottom: 0.5rem;
            }
            > .row {
                .icon.icon-dots {
                    height: 1rem;
                    width: 1rem;
                    margin-right: 0.15rem;
                }
                h3,
                p {
                    text-transform: uppercase;
                    font-weight: 700;
                    margin: 0;
                }
                p {
                    margin-left: auto;
                }
            }
            > p {
                margin: 0;
                font-weight: 300;
                span {
                    text-decoration: underline;
                    font-weight: 500;
                }
            }
        }
    }
}
