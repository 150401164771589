@import 'base';
.screen-messages-footer {
  @include card($background: $color-whiteish, $border-radius: 6px);
  height: 3rem;
  p {
    padding: 0 1vw;
    line-height: 3rem;
    &:not(:last-child) {
      border-right: 1px solid $color-text;
    }
    span {
      font-weight: 700;
    }
  }
}
.message-modal-container {
  width: 100%;
  margin: 10px 0px;
  word-break: break-all;
  font-size: 18px;
}
