@import "base.scss";

.input-datepicker-container {
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    cursor: pointer;
    margin: 0.625rem 0px;
    position: relative;
    &.invalid {
        box-shadow: none !important;
        .react-datepicker__input-container input {
            border: none !important;
            box-shadow: 0 0 3px $color-red !important;
        }
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    input {
        width: 100%;

        height: 2.25rem;
        border: 1px solid rgba(0, 52, 112, 0.3);
        border-radius: 6px;
        padding-left: 0.625rem;
        padding-right: 2.5rem;
        text-overflow: ellipsis;
    }
    > .icon {
        height: 1.875rem;
        width: 1.875rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;
        &.icon-calendar {
            background-image: url("../../../assets/icons/calendar.svg");
        }
        &.icon-clock {
            background-image: url("../../../assets/icons/clock.svg");
        }
    }
    > .icon.icon-calendar,
    > .icon.icon-clock {
        pointer-events: none;
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        top: 50%;
        right: 0.625rem;
        transform: translateY(-50%);
        &:active {
            transform: translateY(-50%);
        }
    }
}
