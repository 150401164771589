@import "base";

.popup-content.modal-container-content.General-content {
    max-height: 90vh;
    width: 90vw;
    .modal-general-container {
        max-height: calc(90vh - 10px - 1vw);
        padding: 0.5vw;
        .modal-general-header {
            justify-content: space-between;
            h2 {
                text-transform: uppercase;
                font-size: 1.5rem;
                font-weight: 700;
                margin: 0.5rem 0;
            }
        }
        .modal-general-content > div {
            max-height: calc(90vh - 10px - 1vw - 50px);
        }
    }
}
