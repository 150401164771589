@import 'base';

html {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  font-size: 14px;
}

#root {
  background-color: $color-whiteish;
}

html,
body,
#root,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
li,
div,
a,
input,
textarea {
  font-family: 'Montserrat', sans-serif;
  color: $color-text;
  font-weight: 400;
}

.row {
  @extend %flex;
  flex-direction: row;
  align-items: center;
}
.col {
  @extend %flex;
  flex-direction: column;
}

body .icon {
  height: 1.875rem;
  width: 1.875rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  &.icon-circle {
    background-color: $color-charts-12;
  }
  &.icon-avatar {
    background-image: url('./assets/icons/avatar.jpeg');
  }
  &.icon-support {
    background-image: url('./assets/icons/support.svg');
  }
  &.icon-settings {
    background-image: url('./assets/icons/settings.svg');
    &.white {
      background-image: url('./assets/icons/settings-white.svg');
    }
  }
  &.icon-plus {
    background-image: url('./assets/icons/plus.svg');
  }
  &.icon-dots {
    background-image: url('./assets/icons/dots.svg');
  }
  &.icon-close {
    background-image: url('./assets/icons/close.svg');
  }
  &.icon-info {
    background-image: url('./assets/icons/info.svg');
    &.darker {
      background-image: url('./assets/icons/info-icon.svg');
    }
  }
  &.header-icon {
    background-image: url('./assets/icons/header-option-icon.svg');
  }
  &.icon-required {
    display: block;
    width: 1rem;
    height: 1.625rem;
    background-image: url('./assets/icons/required.svg');
  }
  &.icon-upload {
    background-image: url('./assets/icons/upload.svg');
  }
  &.icon-search {
    background-image: url('./assets/icons/search.svg');
  }
  &.icon-eye {
    background-image: url('./assets/icons/eye.svg');
  }
  &.icon-eye-closed {
    background-image: url('./assets/icons/eye-closed.svg');
  }
  &.icon-pen {
    background-image: url('./assets/icons/pen.svg');
  }
  &.icon-export {
    background-image: url('./assets/icons/export.svg');
  }
  &.icon-arrow {
    background-image: url('./assets/icons/arrow.svg');
  }
  &.icon-full-arrow {
    background-image: url('./assets/icons/full-arrow-white.svg');
    &.black {
      background-image: url('./assets/icons/full-arrow-black.svg');
    }
    &.red {
      background-image: url('./assets/icons/full-arrow-red.svg');
    }
  }
  &:active {
    transform: scale(0.95);
  }
  &.no-active {
    cursor: default;
    &:active {
      transform: scale(1);
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    &:active {
      transform: scale(1);
    }
  }
  &.no-pointer {
    cursor: default;
  }
}

body .stats-header {
  padding: 1rem;
  border: $color-border 1px solid;
  border-radius: 6px;
  > .row {
    flex: 1;
    margin-right: 0.5rem;
  }
  > .col {
    height: 100%;
    justify-content: space-between;
    align-items: flex-end;
    > .row {
      text-transform: uppercase;
      .icon-settings {
        height: 1.2rem;
        width: 1.2rem;
        min-width: 1.2rem;
        margin-left: 0.5rem;
      }
    }
    span {
      font-size: 0.875rem;
      font-weight: 300;
    }
  }
  .icon.icon-circle {
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    min-width: 2.5rem;
    margin-right: 0.5rem;
  }
  h2 {
    text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: 700;
    margin: 0;
  }
}

body .input-datepicker-container.month-picker .react-datepicker-popper {
  .react-datepicker__month-wrapper {
    display: flex;
    > div {
      margin: 5px 10px;
      //   flex: 1;
    }
  }
}

.main-container {
  padding: 1vw;
  width: 100%;
  height: 95%;
  .inner-header-container {
    width: 100%;
    height: 6%;
    border-radius: 7px;
    padding-left: 1%;
    display: flex;
    align-items: center;
    @include shadow-large;
    .left-part {
      display: flex;
      height: 100%;
      align-items: center;
      width: 90%;
      .close-icon {
        background-image: url('assets/icons/close.svg');
        background-size: contain;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
      .inner-title {
        font-size: 1.25rem;
        text-transform: uppercase;
        font-weight: bold;
        margin: 0px 0px 0px 15px;
      }
    }
  }

  .body-container {
    height: 91%;
    margin-top: 0.5%;
    border-radius: 7px;
    padding: 1vw;
    @include shadow-large;
    .inner-title {
      font-size: 1.25rem;
      margin-left: 1%;
      text-transform: uppercase;
      font-weight: bold;
    }
    &.body-map {
      padding: 0px;
      box-shadow: none;
      margin-top: 0%;
    }
    &.row {
      display: flex;
    }
  }
}

.anvil-content {
  -webkit-animation: anvil 0.2s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards !important;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: black;
  // margin: 10px 0px;
  border-radius: 7px;
  &.vertical {
    height: 100%;
    width: 1px;
    margin: 0px 10px;
  }
  &.black {
    background-color: #313741;
  }
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
