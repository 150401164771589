@import "base";
.screen-articles-container {
    height: calc(100% - 3rem);
    padding: 0.5vw 1vw;
    .screen-articles-inner-container {
        height: 100%;
        @include card($border-radius: 6px);
        padding: 0.5vw 1vw;
        .screen-articles-header {
            .row-settings {
                justify-content: flex-end;
                span {
                    text-transform: uppercase;
                    font-size: 0.875rem;
                    font-weight: 300;
                }
                .icon.icon-settings {
                    height: 1.2rem;
                    width: 1.2rem;
                    min-width: 1.2rem;
                    margin-left: 0.5rem;
                }
            }
            .row-title {
                justify-content: space-between;
                h2 {
                    text-transform: uppercase;
                    font-size: 1.125rem;
                    font-weight: 700;
                    margin: 0.5rem 0;
                }
            }
        }
        .screen-articles-content {
            flex: 1;
            overflow: auto;
            .screen-articles-item-container {
                padding: 0 0.5vw;
                border: 1px solid $color-border;
                @include card($border-radius: 6px);
                &:not(:last-child) {
                    margin-bottom: 1rem;
                }
                &.pinned {
                    background-color: $color-whiteish;
                }
                .icon.icon-cover-photo {
                    background-size: cover;
                    border-radius: 50%;
                    margin-right: 0.5vw;
                    height: 3rem;
                    width: 3rem;
                }
                h3 {
                    font-weight: 700;
                }
                span {
                    font-weight: 700;
                    margin-left: auto;
                }
            }
            .divider {
                height: 1px;
                background-color: #b5bbc1;
                margin: 1rem 0;
            }
        }
    }
}
