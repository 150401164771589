@import "base.scss";
.input-text-container {
    margin: 0.625rem 0;
    box-sizing: border-box;
    position: relative;
    * {
        box-sizing: border-box;
    }
    &.row,
    .row {
        display: flex;
        align-items: center;
    }
    .input-text {
        font-family: "Montserrat", sans-serif;
        width: 100%;
        flex: 1;
        border-radius: 6px;
        padding: 0.5625rem 0.6875rem;
        border: 1px solid $color-border;
        color: $color-text;
        font-size: 0.9425rem;
        height: 2.375rem;
    }
    &.with-icon {
        .input-text {
            padding-right: 2.5rem;
        }
        > .icon {
            position: absolute;
            right: 0.625rem;
            height: 1.25rem;
            width: 1.25rem;
        }
    }
    &.with-label {
        .input-text {
            border-right: 0;
            border-radius: 6px 0 0 6px;
        }
        .label-container {
            border: 1px solid $color-border;
            border-left: none;
            border-radius: 0 6px 6px 0;
            background-color: $color-white;
            height: 2.375rem;
        }
        p {
            font-family: "Montserrat", sans-serif;
            padding: 0 0.5rem;
            border-left: 1px solid $color-border;
            font-size: 0.8125rem;
            font-weight: 300;
        }
    }
    &.invalid {
        border-radius: 6px;
        box-shadow: 0 0 3px $color-red;
        .input-text,
        .label-container {
            border-color: $color-white;
        }
    }
}
