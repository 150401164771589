@import "base";

.shared-sorting-component-container {
    min-width: 200px;
    max-width: 300px;
    padding: 5px 10px;
    position: relative;
    .checkboxes-container {
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
        .checkbox-outer {
            min-width: 18px;
        }
    }
    .buttons-raised-container {
        margin-left: auto;
    }

    .label-container p {
        margin: 6px 0;
        line-height: 1.25rem;
        text-overflow: ellipsis;
        max-width: 300px;
    }

    h3 {
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 700;
        margin: 10px 0;
    }
    p {
        font-size: 0.875rem;
        line-height: 1.25rem;
        margin: 10px 0;
        &.btn-clear {
            cursor: pointer;
            &:active {
                transform: scale(0.95);
            }
        }
    }
    > div:not(:last-child) {
        margin-bottom: 30px;
    }
}
