@import "base";

.screen-login-container {
    height: 100vh;
    justify-content: center;
    .screen-login-inner-container {
        @include card($border-radius: 6px);
        padding: 2rem;
        min-width: 400px;
        h2 {
            text-transform: uppercase;
            font-size: 1.125rem;
            font-weight: 700;
        }
        .buttons-raised-container {
            margin-top: 2rem;
        }
    }
}
