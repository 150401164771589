.screen-app-container {
    height: calc(100% - 3rem);
    overflow-x: auto;
    padding: 0.5vw 0 0.5vw 1vw;
    > .col {
        height: 100%;
        width: 31vw;
        margin-right: 1vw;
        > div {
            flex: 1;
        }
    }
}
