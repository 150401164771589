@import "base";

.profile-visibility-container {
    width: 100%;
    height: 100%;
    @include card($border-radius: 6px);
    padding: 0.75rem;
    &.hidden .profile-visibility-search .input-text-container {
        margin-bottom: 0;
    }

    .profile-visibility-header h2 {
        text-transform: uppercase;
        font-size: 1.125rem;
        font-weight: 700;
        margin: 0.5rem 0;
    }
    .profile-visibility-content {
        flex: 1;
        overflow: auto;
        .profile-visibility-item-container {
            border: 1px solid $color-border;
            border-radius: 6px;
            padding: 0.5vw;
            padding-left: 0;
            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
            .icon.icon-avatar {
                background-size: cover;
                border-radius: 50%;
                margin: 0 0.75vw;
                height: 3rem;
                width: 3rem;
            }
            p {
                margin: 0.35rem 0;
                text-transform: uppercase;
                &.name {
                    font-weight: 700;
                }
            }
            // &:hover {
            //     .icon.icon-eye,
            //     .icon.icon-eye-closed {
            //         display: block;
            //     }
            // }
            .icon.icon-eye,
            .icon.icon-eye-closed {
                // display: none;
                margin-left: auto;
            }
        }
        .row-date {
            p {
                margin: 0.625rem 0;
                font-weight: 700;
            }
            .divider {
                background-color: $color-border;
                height: 1px;
                flex: 1;
                margin-left: 1rem;
            }
        }
    }
}
