@import "base";

.stats-month-container {
    width: 100%;
    height: 100%;
    @include card($border-radius: 6px);
    padding: 0.75rem;
    .stats-month-header {
        justify-content: space-between;
        h2 {
            text-transform: uppercase;
            font-size: 1.125rem;
            font-weight: 700;
            margin: 0.5rem 0;
        }
        > .row {
            align-self: flex-start;
            > span {
                text-transform: uppercase;
                font-size: 0.875rem;
                font-weight: 300;
                margin-bottom: 3px;
            }
            .icon-settings {
                height: 1.2rem;
                width: 1.2rem;
                min-width: 1.2rem;
                margin-left: 0.5rem;
            }
        }
        .input-datepicker-container {
            margin: 0;
            z-index: 3;
            > .icon {
                display: none;
            }
        }
    }
    .stats-month-additional-header {
        justify-content: space-between;
        margin: 0.75rem 0;
        > div {
            align-self: stretch;
            width: calc(50% - 0.5rem);
            background-color: #f4f8fa;
            h2 {
                font-size: 0.875rem;
            }
        }
    }
    .stats-month-content {
        flex: 1;
        overflow: auto;
    }
    .stats-month-inner-content {
        padding: 0.5rem;
        border: $color-border 1px solid;
        border-radius: 6px;
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
        h3 {
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 700;
            margin: 0.5rem 0;
        }
    }
}
