@import "base.scss";
.input-toggle-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-right: 1rem;
    .input-toggle-label {
        color: $color-text;
        margin-right: 0.375rem;
    }
    .input-toggle-inner-container {
        width: 3.75rem;
        height: 1.75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 1.75rem;
        background-color: #7e7e7e;
        position: relative;
        transition: all 0.3s ease-in-out;
        padding: 0px 0.375rem;
        .input-toggle-circle {
            height: 1.5rem;
            width: 1.5rem;
            border-radius: 50%;
            position: absolute;
            left: 2px;
            transition: all 0.3s ease-in-out;
            background-color: white;
        }
    }
    &:hover {
        cursor: pointer;
    }
    &.toggle-on {
        .input-toggle-inner-container {
            background-color: $color-green;
        }
        .input-toggle-circle {
            left: calc(100% - 1.625rem);
        }
    }
    span {
        color: $color-white;
        font-size: 0.8125rem;
        transition: all 0.3s ease-in-out;
        padding-bottom: 2px;
    }
    .label-on {
        margin-left: 3px;
    }

    .label-off {
        margin-right: 3px;
    }

    .toggle-label {
        margin-right: 0.625rem;
    }
    .fade-in {
        opacity: 1;
        transform: scale(1);
    }
    .fade-out {
        opacity: 0;
        transform: scale(0);
    }
}
