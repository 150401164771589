@import '../../../base.scss';
.screen-notifications-content {
  height: calc(100% - 3rem - 1vw);
  .table-container {
    .table-additional-header {
      padding: 0 16px;
      > p {
        @include card;
        border-radius: 6px;
        background-color: $color-whiteish;
        text-align: center;
        margin: 0;
        padding: 5px 0;
        font-weight: 700;
        &:not(:first-child) {
          margin-left: 14px;
        }
        &:nth-child(1) {
          width: 1977px;
        }
        &:nth-child(2) {
          width: 1050px;
        }
        &:nth-child(3) {
          width: 1360px;
        }
      }
      &.fighters > p {
        &:nth-child(1) {
          width: 1620px;
        }
        &:nth-child(2) {
          width: 950px;
        }
      }
    }
    .table-header .table-cell .icon.icon-arrow {
      height: 1.25rem;
      width: 1.25rem;
      margin: 0 5px;
    }
    .table-cell .icon.icon-arrow {
      transition: 0.4s;
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
}
