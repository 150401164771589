@import "base";

.button-tab-container {
    position: relative;
    border: 1px solid #b5bbc1;
    border-radius: 6px;
    .underlined-tab {
        cursor: pointer;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        flex: 1;
        text-align: center;
        z-index: 2;
        text-transform: uppercase;
        &.underlined-tab-active {
            font-weight: 700;
        }
    }
    .active-underlined-selector {
        position: absolute;
        inset: 0px;
        border-radius: 6px;

        transition: all 0.3s ease-in-out;
        background-color: #d3d9de;
        height: 100%;
    }
}
