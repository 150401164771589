@import "base";

.upload-button-container {
    border: 1px dashed $color-border;
    border-radius: 6px;
    padding: 0.75vw 1vw;
    cursor: pointer;
    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }
    &.invalid {
        border-color: transparent;
        box-shadow: 0 0 3px $color-red;
    }
    p {
        text-transform: uppercase;
        font-weight: 700;
        margin: 0;
    }
    .icon.icon-upload {
        height: 1rem;
        width: 1rem;
        margin-left: 3rem;
    }
}
