@import "base.scss";

.input-autocomplete-container {
    position: relative;
    &.disabled {
        pointer-events: none;
    }
    &.open {
        .suggestions-container {
            border-radius: 0px 0px 6px 6px;
        }
        .input-text {
            border-radius: 6px 6px 0 0;
            border-bottom: none;
        }
    }
    .suggestions-container {
        @include card;
        z-index: 1;
        max-height: 300px;
        overflow-y: auto;
        background-color: white;
        position: absolute;
        top: 100%;
        left: 0px;
        right: 0px;
        border-radius: 5px;
        &.active {
            border: 1px solid $color-border;
        }
    }

    .suggestion {
        padding: 10px 10px;
        &:not(:last-child) {
            border-bottom: 1px solid $color-border;
        }
    }

    .suggestion:hover,
    .suggestion-active {
        background-color: rgba(174, 213, 255, 0.5);
    }
}
