@import 'base';

.graph-horizontal-list-container {
  flex-direction: column !important;
  gap: 15px;

  &.row {
    align-items: flex-start;
  }
  .graph-list-item-container {
    width: 100%;
    border: 1px solid #b5bbc1;
    border-radius: 6px;
    padding: 0.8rem;
    background-color: #f4f8fa;
    position: relative;
    z-index: 55;
    .icon.icon-circle {
      border-radius: 50%;
      margin-right: 0.5rem;
      width: 2rem;
      height: 2rem;
    }
    > .row span {
      font-weight: 700;
    }
    > .row p {
      text-transform: capitalize;
      font-weight: 500;
      //   margin: 1rem 0 0.5rem;
      margin: 0rem 1rem;
    }
  }
  .graph-list-item-lower-container {
    border: 1px solid #b5bbc1;
    border-radius: 6px;
    padding: 0.5rem;
    width: 100%;
    margin-top: -20px;
    position: relative;
    z-index: 5;
    background-color: white;
    .icon.icon-square {
      margin-right: 0.5rem;
      width: 0.5rem;
      height: 2rem;
      background-color: #2f4858;
    }
    > .row span {
      font-weight: 700;
    }
    > .row p {
      text-transform: capitalize;
      font-weight: 500;
      //   margin: 1rem 0 0.5rem;
      margin: 0rem 1rem;
    }
    .additional-container {
      border: 1px solid #b5bbc1;
      border-radius: 6px;
      padding: 0.5rem;
      margin: 10px 0px;
    }
  }
}
