.popup-header-options-container {
  padding: 0.5rem 1rem;
  .popup-header-options-header {
    justify-content: space-between;
    h2 {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.125rem;
    }
    .icon.icon-close {
      height: 15px;
      width: 15px;
    }
  }
  .popup-header-options-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .header-option {
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 7px;
      padding: 0.7rem;
      cursor: pointer;
      &.green {
        background-color: #2ad399;
        color: white;
      }
      &.black {
        background-color: #f2f6fa;
      }
      &.red {
        background-color: #ffeff2;
      }
      .icon-full-arrow {
        width: 20px;
        height: 20px;
      }
    }
  }
}
