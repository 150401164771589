@import "base";
.screen-article-container {
    height: calc(100% - 3rem);
    padding: 0.5vw 1vw;
    .screen-article-inner-container {
        height: 100%;
        .screen-article-header {
            @include card($border-radius: 6px);
            padding: 0.5vw 1vw;
            .icon.icon-close {
                height: 1.3rem;
                width: 1.3rem;
                margin-right: 0.5vw;
            }
            h2 {
                text-transform: uppercase;
                font-size: 1.125rem;
                font-weight: 700;
                margin: 0.5rem 0;
            }
            .row-buttons {
                margin-left: auto;
                .buttons-raised-container {
                    margin-left: 0.5vw;
                }
                .input-toggle-container {
                    margin-right: 0;
                    cursor: default;
                    .input-toggle-label {
                        font-size: 0.875rem;
                    }
                }
            }
        }
        .screen-article-content {
            @include card($border-radius: 6px);
            padding: 0.5vw 1vw;
            margin-top: 0.5vw;
            flex: 1;
            overflow: auto;
            .row-photos {
                overflow-x: auto;
                .image:not(:last-child) {
                    margin-right: 2vw;
                }
            }
            .image {
                padding-right: 3rem;
            }
            .image,
            .title,
            .text {
                @include card($background: $color-whiteish, $border-radius: 6px);
                border: 1px solid $color-border;
                padding: 0.75vw 1vw;
                p {
                    margin: 0;
                }
            }
        }
    }
}
