@import "base";
.graph-horizontal-container {
    .graph-horizontal-row-container {
        border: 1px solid #d3d9de;
        border-radius: 6px;
        &:not(:last-child) {
            margin-bottom: 0.5rem;
        }

        .graph-horizontal-row-indicator-outer {
            width: 75%;
            .graph-horizontal-row-indicator {
                padding: 0.25rem 0;
                @include shadow-large;
                border-radius: 5px;
                background-color: $color-charts-5;
                span {
                    visibility: hidden;
                }
            }
        }

        .graph-horizontal-row-text {
            width: 25%;
            justify-content: flex-end;
            margin: 0 0.5rem;
            p {
                margin: 0;
                white-space: nowrap;
                text-transform: capitalize;
                font-weight: 300;
                span {
                    font-weight: 700;
                }
            }
        }
        .icon.icon-info {
            height: 1.3rem;
            width: 1.3rem;
            margin-left: 0.5rem;
        }
    }
}
