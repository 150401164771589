@import "base";

.popup-content.modal-container-content.Payout-content {
    width: 400px;
    .modal-payout-container {
        padding: 0.5vw;
        .modal-payout-header {
            justify-content: space-between;
            h2 {
                text-transform: uppercase;
                font-size: 1.25rem;
                font-weight: 700;
                margin: 0.5rem 0;
            }
            .icon.icon-close {
                height: 20px;
                width: 20px;
            }
        }
        .modal-payout-content {
            margin: 2rem 0;
            .upload-button-container {
                p {
                    margin: 0 auto;
                    text-align: center;
                }
                .icon.icon-upload {
                    margin: 0;
                }
            }
            .image {
                @include card($background: $color-whiteish, $border-radius: 6px);
                border: 1px solid $color-border;
                padding: 0.75vw 1vw;
                p {
                    font-weight: 500;
                    margin: 0 auto;
                    text-align: center;
                }
                .icon.icon-close {
                    height: 1rem;
                    width: 1rem;
                }
            }
        }
        .modal-payout-footer .buttons-raised-container {
            margin-left: auto;
        }
    }
}
