@import 'base';
.screen-revenue-container {
  height: calc(100% - 3rem);
  padding: 0.5vw 1vw;
  .screen-revenue-inner-container {
    height: 100%;
    @include card($border-radius: 6px);
    .screen-revenue-header {
      padding: 0.5vw 1vw;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .button-tab-container {
        max-width: 600px;
        flex: 2 0;
      }
      .icon-settings {
        width: 1.4rem !important;
        height: 1.4rem !important;
        margin-left: 15px;
      }
    }
    .screen-revenue-content {
      height: calc(100% - 3rem - 31px - 1vw);
      .table-container {
        .table-additional-header {
          padding: 0 16px;
          > p {
            @include card;
            border-radius: 6px;
            background-color: $color-whiteish;
            text-align: center;
            margin: 0;
            padding: 5px 0;
            font-weight: 700;
            &:not(:first-child) {
              margin-left: 14px;
            }
            &:nth-child(1) {
              width: 2630px;
            }
            &:nth-child(2) {
              width: 1260px;
            }
            &:nth-child(3) {
              width: 1400px;
            }
          }
          &.fighters > p {
            &:nth-child(1) {
              width: 1620px;
            }
            &:nth-child(2) {
              width: 950px;
            }
          }
        }
        .table-header .table-cell .icon.icon-arrow {
          height: 1.25rem;
          width: 1.25rem;
          margin: 0 5px;
        }
        .table-cell .icon.icon-arrow {
          transition: 0.4s;
          &.rotate {
            transform: rotate(180deg);
          }
        }
      }
    }
    .screen-revenue-footer {
      @include card($background: $color-whiteish, $border-radius: 6px);
      height: 3rem;
      p {
        padding: 0 1vw;
        line-height: 3rem;
        &:not(:last-child) {
          border-right: 1px solid $color-text;
        }
        span {
          font-weight: 700;
        }
      }
    }
  }
}
