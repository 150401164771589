.popups-select-period-container {
    .row-datepicker {
        span {
            width: 70px;
        }
        .react-datepicker__close-icon {
            right: 24px;
            top: 1px;
            &::after {
                background-color: #924490;
                font-size: 14px;
            }
        }
    }
}
