@import "base.scss";

.input-radio-button-container {
    margin: 0.625rem 0;
    &.row,
    .row {
        display: flex;
        align-items: center;
    }
    &.col,
    .col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .single-radio-button {
        margin-right: 1rem;
        cursor: pointer;
        &.reversed {
            flex-direction: row-reverse;
            .radio-button-outer {
                margin: 0 0 0 0.375rem;
            }
        }
        &.disabled {
            cursor: default;
        }
        &.selected {
            .radio-button-outer .radio-button-inner {
                background-color: $color-text;
                width: 50%;
                height: 50%;
                border-radius: 100%;
                transition: all 150ms linear;
            }
            p {
                font-weight: 700;
                transition: all 150ms linear;
            }
        }
        p {
            white-space: nowrap;
            margin: 0;
            line-height: 2.375rem;
        }

        .radio-button-outer {
            border: solid 1px $color-text;
            border-radius: 100%;
            width: 1.2rem;
            height: 1.2rem;
            margin-right: 0.375rem;
            display: flex;
            justify-content: center;
            align-items: center;
            .radio-button-inner {
                width: 0px;
                height: 0px;
            }
        }
        .button-image {
            width: 1.2rem;
            height: 1.2rem;
            background-size: cover;
            border-radius: 100%;
            margin: 0 0.375rem;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    &.invalid .radio-button-outer {
        box-shadow: 0 0 10px 1px $color-red;
        border-color: $color-white;
    }
}
