@import "base.scss";

.buttons-raised-container {
    position: relative;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 2.25rem;
    padding: 0px 1.5625rem;
    margin: 0.625rem 0px;

    background-color: $color-red;
    box-shadow: 0px 1px 4px $color-border;
    border-radius: 6px;

    font-family: "Montserrat", sans-serif;
    color: $color-white;
    font-weight: 500;
    font-size: 1rem;

    cursor: pointer;
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    &:active {
        transform: scale(0.99);
    }
    .circle {
        position: absolute;
        background-color: white;
        opacity: 0.6;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0);
        animation: scale 0.5s ease-out;
    }
    &.reversed {
        background-color: $color-white;
        color: $color-red;
        border: 1px solid $color-red;
    }
    &.gray{
        background-color: $color-border;
        color: $color-white;
        border: 1px solid $color-border;
    }
}
@keyframes scale {
    to {
        transform: translate(-50%, -50%) scale(3);
        opacity: 0;
    }
}
