@import "base";
.screen-payout-container {
    height: calc(100% - 3rem);
    padding: 0.5vw 1vw;
    .screen-payout-inner-container {
        height: 100%;
        .screen-payout-header {
            @include card($border-radius: 6px);
            padding: 0.5vw 1vw;
            height: 60px;
            .icon.icon-close {
                height: 1.3rem;
                width: 1.3rem;
                margin-right: 0.5vw;
            }
            h2 {
                text-transform: uppercase;
                font-size: 1.125rem;
                font-weight: 700;
                margin: 0.5rem 0;
            }
        }
        .empty-state {
            @include card($border-radius: 6px);
            padding: 0.5vw 1vw;
            margin-top: 0.5vw;
            height: calc(100% - 0.5vw - 60px);
            overflow: hidden;
        }
        .screen-payout-content {
            @include card($border-radius: 6px);
            padding: 0.5vw 1vw;
            margin-top: 0.5vw;
            height: calc(100% - 0.5vw - 60px);
            overflow: hidden;
            h3 {
                text-transform: uppercase;
                font-size: 1.125rem;
                font-weight: 700;
                margin: 0.5rem 0;
            }
            .bank-account-container {
                padding: 0.5vw 1vw;
                border: 1px solid $color-border;
                border-radius: 6px;
                height: 65px;
                h3 {
                    margin: 0;
                }
                p {
                    margin: 0.3vw 0 0;
                    span {
                        font-weight: 700;
                    }
                    &:not(:last-child) {
                        border-right: 1px solid $color-border;
                        padding-right: 10px;
                        margin-right: 10px;
                    }
                }
            }
            .payouts-container {
                padding-top: 1.5vw;
                height: calc(100% - 65px - 2.5vw);
                .table-container .table-row-cell.file .buttons-raised-container {
                    background-color: $color-green;
                    margin: 0;
                    height: 1.5rem;
                    font-size: 0.875rem;
                    margin-right: 15px;
                }
            }
        }
    }
}
