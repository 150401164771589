@import "base";

.stats-sessions-container {
    width: 100%;
    height: 100%;
    @include card($border-radius: 6px);
    padding: 0.75rem;
    .stats-sessions-additional-header {
        justify-content: space-between;
        margin: 0.75rem 0 0;
        > div {
            align-self: stretch;
            width: calc(50% - 0.5rem);
            background-color: #f4f8fa;
            h2 {
                font-size: 0.875rem;
            }
        }
    }
    .stats-sessions-navigation .button-tab-container {
        margin: 0.75rem 0;
    }
    .stats-sessions-content {
        flex: 1;
        overflow: auto;
    }
}
