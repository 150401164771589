@import "base";

.stats-profiles-container {
    width: 100%;
    height: 100%;
    @include card($border-radius: 6px);
    padding: 0.75rem;
    .stats-profiles-header {
        justify-content: space-between;
        h2 {
            text-transform: uppercase;
            font-size: 1.125rem;
            font-weight: 700;
            margin: 0.5rem 0;
        }
    }
    .stats-profiles-navigation .button-tab-container {
        margin: 0.75rem 0;
        overflow-x: scroll;
    }
    .stats-profiles-content {
        flex: 1;
        overflow: auto;
        margin-top: 0.5rem;
        > div {
            border: 1px solid $color-border;
            border-radius: 6px;
            padding: 0.5rem;
            &:nth-child(1),
            &:nth-child(2) {
                max-height: 30vh;
            }
            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
            h3 {
                font-size: 1rem;
                margin: 0 0 0.5rem;
                font-weight: 700;
                text-transform: uppercase;
            }
            > div {
                overflow: auto;
            }
        }
    }
}
