@import "base";

.popup-content.modal-container-content.InfoUsers-content {
    .modal-info-users-container {
        padding: 0.5vw;
        .modal-info-users-header {
            justify-content: space-between;
            h2 {
                text-transform: uppercase;
                font-size: 1.25rem;
                font-weight: 700;
                margin: 0.5rem 0;
            }
            .icon.icon-close {
                height: 20px;
                width: 20px;
            }
        }
        .modal-info-users-content {
            margin: 0.5vw 0;
            max-height: 30vh;
            overflow: auto;
            .icon.icon-avatar {
                height: 3rem;
                width: 3rem;
                border-radius: 50%;
                background-size: contain;
                margin-right: 0.5vw;
            }
            p {
                font-size: 1.25rem;
                span {
                    font-weight: 700;
                    text-transform: capitalize;
                }
            }
        }
    }
}
