@import 'base';

.stats-messages-container {
  width: 100%;
  height: 100%;
  @include card($border-radius: 6px);
  padding-top: 0.75rem;
  > div:not(.stats-messages-footer) {
    padding: 0 0.75rem;
  }
  .stats-messages-header {
    justify-content: space-between;
    margin-bottom: 0.75rem;
    h2 {
      text-transform: uppercase;
      font-size: 1.125rem;
      font-weight: 700;
      margin: 0.5rem 0;
    }
    > .row {
      align-self: flex-start;
      justify-content: space-between;
      span {
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 300;
      }
      .icon-settings {
        height: 1.2rem;
        width: 1.2rem;
        min-width: 1.2rem;
        margin-left: 0.5rem;
      }
    }
  }
  // .stats-messages-navigation .button-tab-container {
  //     margin: 0.75rem 0;
  // }
  .stats-messages-content {
    overflow: auto;
    display: flex;
    gap:10px;
    flex-direction: column;
    .stats-messages-item-container {
      border: 1px solid $color-border;
      border-radius: 6px;
      padding: 0.5rem;
      position: relative;
      z-index: 20;
      background-color: white;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
      > .row {
        margin-bottom: 0.5rem;
        justify-content: space-between;
        .icon.icon-dots {
          height: 0.625rem;
          width: 0.625rem;
          margin-right: 0.15rem;
        }
        h3,
        p {
          text-transform: uppercase;
          font-weight: 700;
          margin: 0;
        }
        p {
          margin-left: auto;
        }
      }
      > p {
        margin: 0;
      }
    }
    .stats-messages-item-lower-container {
      border: 1px solid $color-border;
      border-radius: 6px;
      width: 100%;
      margin-top: -15px;
      position: relative;
      z-index: 5;
      padding-top: 10px;
      padding-left: 5px;
      padding-right: 5px;
      background-color: white;
      > .row {
        justify-content: space-between;
        flex-wrap: wrap;
        p {
          margin: 10px 0px;
        }
      }
    }
  }
  .stats-messages-footer {
    @include card($background: $color-whiteish);
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    &:active {
      transform: scale(0.98);
    }
    .stats-messages-inner-footer {
      border: 1px dashed $color-text;
      border-radius: 6px;
      position: relative;
      justify-content: center;
      .icon.icon-plus {
        position: absolute;
        left: 1rem;
        height: 20px;
        width: 20px;
      }
      p {
        text-transform: uppercase;
        line-height: 40px;
        text-align: center;
        margin: 0;
      }
    }
  }
}
