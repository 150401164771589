@import "base";
.screen-activities-container {
    height: calc(100% - 3rem);
    padding: 0.5vw 1vw;
    .screen-activities-inner-container {
        height: 100%;
        @include card($border-radius: 6px);
        .screen-activities-header {
            padding: 0.5vw 1vw;
            height: 3rem;
            h2 {
                text-transform: uppercase;
                font-size: 1.125rem;
                font-weight: 700;
                margin: 0.5rem 0;
            }
        }
        .screen-activities-content {
            height: calc(100% - 6rem);
            .table-container {
                .table-header .table-cell .icon.icon-arrow {
                    height: 1.25rem;
                    width: 1.25rem;
                    margin: 0 5px;
                }
            }
        }
        .screen-activities-footer {
            @include card($background: $color-whiteish, $border-radius: 6px);
            height: 3rem;
            p {
                padding: 0 1vw;
                line-height: 3rem;
                &:not(:last-child) {
                    border-right: 1px solid $color-text;
                }
                span {
                    font-weight: 700;
                }
            }
        }
    }
}
