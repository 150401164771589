@import "../../../base.scss";
.checkboxes-container {
  display: flex;
  margin: 10px 5px;
  &.special {
    margin: 0;
  }
  width: 99%;
  &.column {
    flex-direction: column;
  }
  .checkbox-container {
    display: flex;
    margin-right: 5%;
    align-items: center;
    width: 100%;
    @include shadow-large;
    height: 55px;
    padding: 10px;
    &.column {
      margin: 10px 0px;
      box-shadow: none;
    }
    &.special {
      height: 36px;
      margin: 0px !important;
    }
    .outer-square {
      border: 1px solid black;
      border-radius: 5%;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .tick {
        display: none;
        width: 17px;
        height: 17px;
        // background-image: url("../../../assets/icons/green-checkmark.svg");
        background-size: contain;
        &.special {
          width: 13px;
          height: 13px;
        }
        &.selected {
          display: block;
        }
      }
    }
  }
}
