@import "base.scss";
.input-text-area-container {
    margin: 10px 0;
    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
    &.row,
    .row {
        display: flex;
        align-items: center;
    }
    .input-text-area {
        font-family: "Montserrat", sans-serif;
        width: 100%;
        flex: 1;
        border-radius: 5px;
        padding: 9px 11px;
        border: 1px solid $color-border;
        color: $color-text;
        font-size: 13px;
        height: 38px;
    }
    &.invalid {
        border-radius: 6px;
        box-shadow: 0 0 3px $color-red;
        .input-text-area {
            border-color: $color-white;
        }
    }
}
