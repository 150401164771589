@import 'base';
.explore-tab-order-container {
  width: 100%;
  height: 100%;
  @include card($border-radius: 6px);
  padding: 0.75rem;
  .explore-tab-order-header h2 {
    text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: 700;
    margin: 0.5rem 0;
  }
  .explore-tab-order-options .input-radio-button-container {
    .radio-button-outer {
      border-radius: 6px;
      width: 1.25rem;
      height: 1.25rem;
    }
    .single-radio-button.selected .radio-button-inner {
      border-radius: 4px;
      width: 0.875rem;
      height: 0.875rem;
    }
  }
  &.events .explore-tab-order-text {
    display: block;
    margin: 0.625rem 0;
  }
  .explore-tab-order-text {
    margin-bottom: 0.625rem;
    font-size: 0.875rem;
    font-weight: 300;
  }
  .explore-tab-order-buttons {
    margin-bottom: 0.625rem;
    .buttons-raised-container {
      margin: 0;
      text-transform: uppercase;
    }
    > span {
      cursor: pointer;
      height: 2.25rem;
      > span {
        height: 1.125rem;
        width: 1.125rem;
        display: block;
        margin-left: 0.5rem;
      }
    }
  }
  .explore-tab-order-content {
    flex: 1;
    overflow: auto;
    .explore-tab-order-item-outer-container {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
      .explore-tab-order-item-index {
        flex: 0.05;
        background-color: $color-whiteish;
        border: 1px solid $color-border;
        border-radius: 6px;
        align-self: stretch;
        text-align: center;
        padding: 0.5vw;
        margin-right: 0.5rem;
        p {
          font-weight: 700;
        }
      }
      .input-autocomplete-container {
        flex: 1;
        align-self: stretch;
        .suggestions-container .suggestion {
          font-size: 1rem;
          font-weight: 700;
        }
        .input-text-container {
          margin: 0;
          height: 100%;
          .input-text {
            height: 100%;
            line-height: 100%;
            font-size: 1rem;
            font-weight: 700;
          }
          &.with-icon {
            .input-text {
              padding: 0.5625rem 0.5vw;
              padding-left: 4rem;
            }
          }
        }
        .icon.icon-avatar {
          left: 0.625rem;
          height: 3rem;
          width: 3rem;
          border-radius: 50%;
          background-size: contain;
          margin-right: 0.5vw;
        }
      }
      .explore-tab-order-item-container {
        flex: 1;
        border: 1px solid $color-border;
        border-radius: 6px;
        padding: 0.5vw;
        padding-left: 0;
        .icon.icon-avatar {
          background-size: cover;
          border-radius: 50%;
          margin: 0 0.75vw;
          height: 3rem;
          width: 3rem;
        }
        p {
          margin: 0.35rem 0;
          text-transform: uppercase;
          &.name {
            font-weight: 700;
          }
          &.type {
            font-size: 13px;
          }
        }
      }
    }
  }
}
