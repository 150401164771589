@import "base";

.graph-list-container {
    flex-wrap: wrap;
    justify-content: space-between;
    &.row {
        align-items: flex-start;
    }
    .graph-list-item-container {
        width: calc(50% - 0.25rem);
        border: 1px solid #b5bbc1;
        border-radius: 6px;
        &:not(:nth-last-child(1)):not(:nth-last-child(2)) {
            margin-bottom: 1rem;
        }

        padding: 0.5rem;
        .icon.icon-circle {
            border-radius: 50%;
            margin-right: 0.5rem;
        }
        > .row span {
            font-weight: 700;
        }
        > p {
            text-transform: capitalize;
            font-weight: 500;
            margin: 1rem 0 0.5rem;
        }
    }
}
