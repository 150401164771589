.popup-export-options-container {
    padding: 0 0.5rem;
    .popup-export-options-header {
        justify-content: space-between;
        h2 {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1.125rem;
        }
        .icon.icon-close {
            height: 20px;
            width: 20px;
        }
    }
}
