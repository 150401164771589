@import "base";

.table-container {
    min-height: 100%;
    max-height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    &.hasSwiped {
        cursor: grabbing;
    }
    &.joinRightSide {
        .table-header,
        .table-content .table-row-container {
            padding-right: 0;
            .table-row-inner-container {
                border-right: none;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
    &.joinLeftSide {
        .table-header,
        .table-content .table-row-container {
            padding-left: 0;
            .table-row-inner-container {
                border-left: none;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
    &.joinLeftSide.joinRightSide {
        .table-header,
        .table-content .table-row-container {
            padding: 0;
        }
    }
    .table-header {
        padding: 10px 16px;
        .table-cell {
            font-weight: 500;
            padding-left: 14px;
            &:not(:first-child) {
                border-left: solid 1px transparent;
            }
            .icon-arrow-down {
                min-width: auto;
                margin: 0 10px;
                height: 20px;
                width: 20px;
            }
        }
    }
    .table-content {
        .table-row-container {
            overflow: hidden;
            padding: 0 16px 0;
            transition: all 0.4s ease-in;
            &.active {
                > .table-row-inner-container {
                    margin-bottom: 0;
                }
                > .table-row-children-container {
                    border-color: #00347026;
                    margin-bottom: 5px;
                }
            }

            .table-row-inner-container {
                box-shadow: 0px 1px 4px 0px #00347026;
                border-radius: 6px;
                max-height: 60px;
                padding: 10px 0;
                align-items: stretch;
                .table-cell {
                    white-space: nowrap;
                    overflow: auto;
                    @include remove-scrollbars;
                    padding-left: 14px;
                    &:not(:first-child) {
                        border-left: solid 1px #00347026;
                    }
                    &.selection {
                        padding: 0;
                    }
                }
            }
            .table-row-children-container {
                height: 0;
                overflow: hidden;
                transition: all 0.4s ease-in;
                border-radius: 0 0 6px 6px;
                border: 2px solid transparent;
                border-top: none;
            }
        }
    }
    .table-content-empty {
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .table-cell.selection {
        padding: 0;
        input[type="checkbox"] {
            display: none;
        }
        input[type="checkbox"]:disabled + label .check-box-image-container {
            opacity: 0.3;
            cursor: not-allowed;
        }
        input[type="checkbox"].hidden + label .check-box-image-container {
            display: none;
        }
        label {
            --check-color: black;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 10px 0;
            cursor: pointer;
            transition: all 0.4s ease-in;
            position: relative;
            top: 0;
        }
        .check-box-image-container {
            display: flex;
            width: 25px;
            height: 25px;
            border: 2px solid gray;
            border-radius: 6px;
            overflow: hidden;
            .check-box-image {
                display: flex;
                width: 25px;
                opacity: 0;
                align-items: center;
                justify-content: center;
                transition: ease-in-out 0.2s all;
                transform: scale(1.8);
                .check-box-image-check {
                    background-image: none;
                    display: inline-block;
                    transform: rotate(45deg) translate(-1px, -1px);
                    height: 20px;
                    width: 8px;
                    border-bottom: 3px solid var(--check-color);
                    border-right: 3px solid var(--check-color);
                    border-radius: 2px;
                }
            }
        }

        input[type="checkbox"]:checked + label .check-box-image-container {
            border-color: var(--check-color);
            .check-box-image {
                opacity: 1;
                transform: scale(0.95);
            }
        }
    }
}

.table-row-container.loader-inline {
    height: 100%;
    width: 100%;
    justify-content: center;
    .lds-facebook {
        display: inline-block;
        position: relative;
        width: 50px;
        height: 50px;
    }
    .lds-facebook div {
        display: inline-block;
        position: absolute;
        left: 5px;
        width: 10px;
        background: $color-text;
        animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }
    .lds-facebook div:nth-child(1) {
        left: 5px;
        animation-delay: -0.24s;
    }
    .lds-facebook div:nth-child(2) {
        left: 20px;
        animation-delay: -0.12s;
    }
    .lds-facebook div:nth-child(3) {
        left: 35px;
        animation-delay: 0;
    }
    @keyframes lds-facebook {
        0% {
            top: 0px;
            height: 50px;
        }
        50%,
        100% {
            top: 12px;
            height: 24px;
        }
    }
}
