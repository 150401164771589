@import "base";

.overlay-activities-users-container {
    height: 100%;

    padding: 0.5vw 1vw;
    .overlay-activities-users-header {
        justify-content: space-between;
        margin-bottom: 0.5vw;
        h2 {
            text-transform: uppercase;
            font-size: 1.125rem;
            font-weight: 700;
            margin: 0.5rem 0;
        }
    }
    .overlay-activities-users-content {
        height: calc(100% - 33px - 0.5vw);
        overflow: auto;
        .user-single {
            border: 1px solid #2f4858;
            border-radius: 6px;
            padding: 0.5vw;
            &:not(:last-child) {
                margin-bottom: 15px;
            }
            .icon.icon-avatar {
                // border: 1px solid #2f4858;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                margin-right: 15px;
            }
            .col {
                p {
                    text-transform: uppercase;
                    font-weight: 700;
                    margin: 0;
                }
                span {
                    text-transform: uppercase;
                    font-weight: 300;
                }
            }
            > p {
                margin: 0;
                margin-left: auto;
                text-transform: uppercase;
                font-weight: 700;
            }

            // p {
            //     margin: 0;
            // }
        }
    }
}
