@import '../../../base.scss';
.user-activity-container {
  width: 100%;
  padding: 0.5rem;
  border: $color-border 1px solid;
  border-radius: 6px;
  margin: 15px 0px;
  justify-content: space-between;
  .user-avatar {
    background-size: cover;
    background-position: center;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100%;
  }
  > div {
    .user-name {
      font-size: 17px;
      font-weight: bold;
      text-transform: uppercase;
      margin-top: 0px;
      margin-bottom: 10px;
    }
    .user-role {
      font-size: 14px;
      text-transform: uppercase;
      margin: 0px !important;
    }
  }
  .user-info {
    font-size: 14px;
    height: 100%;
    &.border {
      border-left: 1px solid $color-border;
      padding: 0px 10px;
    }
  }
}
