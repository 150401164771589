@import 'base';
.header-container {
  background: $color-white;
  @include shadow-small;
  width: 100%;
  height: 3rem;
  padding: 0 1vw;
  .header-left {
    flex: 1;
    height: 100%;
    > a,
    > a img {
      height: 100%;
      padding: 3px 0;
    }
  }
  .header-links {
    justify-content: center;
    height: 100%;
    position: relative;
    > a {
      @extend %flex;
      align-items: center;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1rem;
      height: 100%;
      padding: 0 5px;
      transition: font-weight 0.3s linear;
      &:not(:first-child) {
        margin-left: 3.125rem;
      }
      &.active {
        font-weight: 700;
      }
    }
    .active-route-selector {
      position: absolute;
      height: 0px;
      width: 0px;
      bottom: 0px;
      left: 0px;
      transition: all 0.3s ease-in-out;
      background-color: $color-text;
      &.selector-add-height {
        height: 0.375rem;
      }
    }
  }
  .header-right {
    justify-content: flex-end;
    flex: 1;
    > .header-icon {
      border-radius: 50%;
      background-size: cover;
      height: 2.325rem;
      width: 2.325rem;
    }
    > * {
      margin-left: 1rem;
    }
  }
}

.header-popup-content {
  width: 300px !important;
}
