@import "base";

.popup-content.modal-container-content.EventsTotalViews-content {
    max-height: 90vh;
    width: 90vw;
    .modal-events-total-views-container {
        max-height: calc(90vh - 10px - 1vw);
        padding: 0.5vw;
        .modal-events-total-views-header {
            h2 {
                text-transform: uppercase;
                font-size: 1.5rem;
                font-weight: 700;
                margin: 0.5rem 0;
            }
            .icon.icon-arrow {
                transform: rotate(90deg);
                height: 20px;
                width: 20px;
                margin-right: 15px;
            }
            .icon.icon-close {
                margin-left: auto;
            }
        }
        .modal-events-total-views-content > div {
            max-height: calc(90vh - 10px - 1vw - 50px);
            .lds-facebook {
                height: 60px;
            }
        }
    }
}
